import Vuex from 'vuex'
import user from './user'
import createPersistedstate from 'vuex-persistedstate'

const store = new Vuex.Store({
    modules: {
        user
    },
    plugins: [
        createPersistedstate({
            key: 'user',
            paths: ['user']
        })
    ]
})

export default store