const user = {
    state: {
        uid: 0,
    },
    mutations: {
        USER_INFO(state, info) {
            state.uid = info
        }
    },
    actions: {
        saveUserInfo({ commit }, data) {
            commit('USER_INFO', data)
        }
    },
    getters: {
        isLogin(state) {
            return state.uid && state.uid > 0
        }
    }
};
export default user