import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.array.push.js";
import BottomBar from '@/components/Bottom.vue';
import router from './router';
// import { el } from 'element-plus/es/locale';
export default {
  name: 'App',
  components: {
    BottomBar: BottomBar
  },
  methods: {
    onDownloadClick: function onDownloadClick() {
      if (this.isApple()) {
        // window.location.href = 'https://apps.apple.com/cn/app/%E5%90%8C%E5%AD%A6%E6%8E%A8/id6449519229'
        window.open('https://apps.apple.com/cn/app/%E5%90%8C%E5%AD%A6%E6%8E%A8/id6449519229');
      } else {
        window.location.href = 'https://img.nygread.com/apk/tongxuetui_cl1.apk';
      }
    },
    _isMobile: function _isMobile() {
      var flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      return flag;
    },
    isApple: function isApple() {
      var MAC = /macintosh|mac os x/i.test(navigator.userAgent) && !window.MSStream;
      return MAC;
    },
    goComic: function goComic() {
      router.push('comic');
    },
    goAbout: function goAbout() {
      router.push('about');
    },
    goChat: function goChat() {
      router.push('chat');
    }
  },
  mounted: function mounted() {
    if (this._isMobile()) {
      // window.location.href = 'https://h5.tongxuetui.com/'
    }
  }
};