import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/comic',
        name: 'comic',
        component: () => import('../views/ComicView.vue')
    },
    {
        path: '/detail',
        name: 'detail',
        component: () => import('../views/ComicDetailView.vue')
    },
    {
        path: '/read',
        name: 'read',
        component: () => import('../views/ComicReadView.vue')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('../views/AboutView.vue')
    },
    {
        path: '/agree',
        name: 'agree',
        component: () => import('../views/Agree.vue')
    },
    {
        path: '/contentAgree',
        name: 'contentAgree',
        component: () => import('../views/ContentAgree.vue')
    },
    {
        path: '/chat',
        name: 'chat',
        component: () => import('../views/Chat.vue')
    },
    {
        path: '/write',
        name: 'write',
        component: () => import('../views/Write.vue')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
